.page-maintenance{}
.section-maintenance{
    display: flex;
    height: 100%;
    background-color: #000000;
    color: #bcbabb;
    display: flex;
    text-align: center;
}
.container-maintenance{
    margin:auto;
    text-align: center;
}

.container-maintenance h1{
    margin:20px 0 10px;
 }
 .container-maintenance p{
    font-size: .875em;
    color: #bcbabb;
}

.container-maintenance .calltoaction{
    margin:10px 0;
}

.container-maintenance-gif{
    overflow: hidden;
    width: 250px;
    height: 250px;
    margin:20px auto;
}

.dim-children {
    text-decoration: none;
}

.txt-icon{
    background-color: #00aeff;
    padding: 5px 10px;
    border-radius: 10px;
    margin: 20px;
}